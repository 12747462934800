<!-- 
	author: 张洪志
	description: 注册
 -->
<template>
	<section class="body">
		<div class="reg vw">
			<div class="step step-1" v-if="step===1">
				<div class="nav">
					<img src="@/static/img/reg/step_1.png">
				</div>
				<div class="enter-mobile">
					输入手机号
				</div>
				<div class="content-box">
					<div class="gray">
						请输入绑定手机号
					</div>
					<div class="flex-between mobile-box input-box">
						<div class="pre-mobile">
							中国(+86)>
						</div>
						<div>
							<input type="text" class="mobile" placeholder="手机号" v-model="mobile" @keyup="voliMobile" maxlength="11">
						</div>
						<div>
							<span :class="[mobileState ? 'ico-right green' : 'ico-error red', {'green':mobileState}]" v-show="mobile"></span>
						</div>
					</div>
					<div class="gray">
						点击获取验证码,短信将安全发送到您的手机
					</div>
					<div class="flex-between">
						<div class="input-box msg-code-box flex-between">
							<input class="msg-code" placeholder="验证码" v-model="msgCode" @keyup="voliMsgCode" maxlength="6">
							<span :class="[msgCodeState ? 'ico-right green' : 'ico-error red', {'green':msgCodeState}]" v-show="msgCode"></span>
						</div>
						<div :class="['input-box send-msg-box btn btn-orange-plain',{'disable-plain' : !sendCodeAble}]" @click="sendMsgCode">
							<span>{{ msgCodeTxt }}</span>
						</div>
					</div>
					<div class="msg-error">
						{{ step1Error }}
					</div>
					<div class="inviter-box flex-between">
						<div>
							<span class="ico ico-inviter orange"></span>
							<span>我有邀请码</span>
						</div>
						<div class="orange">
							<span :class="['btn ico',showInvitor ? 'ico-reduce' : 'ico-add']" @click="toggleInvitor"></span>
						</div>
					</div>
					<div class="input-box" v-show="showInvitor">
						<input placeholder="输入邀请码" type="text" v-model="inviteCode">
					</div>
					<div class="next">
						<input type="button" :class="['btn-next btn-orange',{'disable':!step1Able}]" value="下一步" @click="goStep2">
					</div>
				</div>
				<div class="agreement">
					<span :class="['ico-right btn',{green : agree}]" @click="toggleAgree"></span>
					注册即为同意
					  <a v-for="(item,i) in registerdata" :key="i" @click="item.protUrl?toPdf(item.protUrl):tofundnotice(item.id)" class="orange">{{item.protNameDesc||'《'+item.infotitle+'》'}}
						<span v-if="i<registerdata.length-1">、</span>
					  </a>
				</div>
			</div>
			<div class="step step-2" v-if="step===2">
				<div class="nav">
					<img src="@/static/img/reg/step_2.png">
				</div>
				<div class="enter-mobile">
					设置登录密码
				</div>
				<div class="content-box">					
					<div class="gray">
						请设置6~16位数字、字母组合、区分大小写
					</div>
					<div class="input-box flex-between">
						<input placeholder="请输入登录密码" v-model="pwd1" :type="pwd1Show ? 'text' : 'password'" maxlength="16" @keyup="checkPwd1">
						<span :class="['btn ico',pwd1Show ? 'ico-eye-open' : 'ico-eye-close']" @click="pwd1Show = !pwd1Show"></span>
					</div>
					<div class="input-box flex-between">
						<input placeholder="再次输入登录密码" v-model="pwd2" :type="pwd2Show ? 'text' : 'password'" maxlength="16" @keyup="checkPwd2">
						<span :class="['btn ico',pwd2Show ? 'ico-eye-open' : 'ico-eye-close']" @click="pwd2Show = !pwd2Show"></span>
					</div>
					<div class="msg-error">
						{{step2Error}}
					</div>
					<div class="next">
						<input type="button" :class="['btn-next btn-orange',{'disable':!step2Able}]" value="确认" @click="goStep3">
					</div>
				</div>
			</div>
			<div class="step step-3" v-if="step===3">
				<div class="nav">
					<img src="@/static/img/reg/step_3.png">
				</div>
				<div class="content-box ">
					<span class="ico-success"></span>
					<div class="success-msg">
						<b>注册成功!</b>
						<p>即刻开户开启财富人生</p>
					</div>
					<a class="btn btn-orange-plain go-open" @click="goOpen">前往开户</a>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { getDicts } from "@/api/dict.js";
import { protPage } from "@/api/other/index.js";
import { sendMsgCode, valiMsgCode, register } from "@/api/user.js";
import { setToken, setMobile, setUserId } from "@/utils/auth.js";
import { mapMutations } from "vuex";

export default {
  name: "Reg",
  data() {
    return {
      step: 1,
      mobile: "",
      mobileState: false,
      msgCode: "",
      msgCodeState: false,
      inviteCode: null,
      sendCodeAble: true,
      msgCodeTxt: "发送验证码",
      step1Error: "",
      showInvitor: false,
      step1Able: true,

      uuid: "",

      pwd1: "",
      pwd1Show: false,
      pwd2: "",
      pwd2Show: false,
      step2Error: "",
      step2Able: true,

      agree: false,
    registerdata:[],//协议
    };
  },
  created() {
    this.mobile = this.$route.params.mobile || "";
    if (this.mobile) {
      this.mobileState = true;
    }
    protPage({
      pageNum: 1,
      pageSize: 100,
      protLink: "1",
      protType: "1"
    }).then(result => {
            this.registerdata = result.data.data || [];

    });
  },
  methods: {
    ...mapMutations(["SET_TOKEN", "SET_UID", "SET_MOBILE"]),
    /** 验证手机格式 */
    voliMobile() {
      let mobile = this.mobile.replace(/[^\d]/g, "");
      this.mobile = mobile;
      if (/^1[3-9]\d{9}$/.test(mobile)) {
        this.mobileState = true;
      } else {
        this.mobileState = false;
      }
    },
    /** 验证码验证 */
    voliMsgCode() {
      this.msgCodeState = this.msgCode && this.msgCode.length === 6;
    },
    /** 发送验证码 */
    sendMsgCode() {
      if (!this.sendCodeAble) {
        return;
      }
      if (!this.mobileState) {
        this.step1Error = "请输入正确的手机号!";
        return;
      }
      this.step1Error = "";
      this.sendCodeAble = false;
      this.msgCodeTxt = "正在发送……";
      sendMsgCode(this.mobile, "1")
        .then(() => {
          let count = 60;
          this.msgCodeTxt = "重新发送(" + count + ")";
          let interval = setInterval(() => {
            count--;
            if (count === 0) {
              this.msgCodeTxt = "发送验证码";
              this.sendCodeAble = true;
              clearInterval(interval);
            } else {
              this.msgCodeTxt = "重新发送(" + count + ")";
            }
          }, 1000);
          this.step1Error = "验证码已发送,请查看您的手机短信!";
        })
        .catch(error => {
          this.step1Error = error;
          this.msgCodeTxt = "发送验证码";
          this.sendCodeAble = true;
        });
    },
    /** 切换邀请码显示 */
    toggleInvitor() {
      this.showInvitor = !this.showInvitor;
    },
    /** 切换协议同意状态 */
    toggleAgree() {
      this.agree = !this.agree;
    },
    /** 前往注册第二步 */
    goStep2() {
      if (!this.step1Able) {
        return;
      }
      if (!this.mobileState) {
        this.step1Error = "请输入正确的手机号";
        return;
      }
      if (!this.msgCodeState) {
        this.step1Error = "请输入正确的验证码";
        return;
      }
      if (!this.agree) {
        this.step1Error = "请同意注册相关协议";
        return;
      }
      this.step1Able = false;
      valiMsgCode(this.mobile, this.msgCode, "1")
        .then(result => {
          this.step = 2;
          this.uuid = result.data;
        })
        .catch(error => {
          this.step1Error = error;
        })
        .finally(() => {
          this.step1Able = true;
        });
    },
    /** 前往注册第三步 */
    goStep3() {
      if (!this.pwd1) {
        this.step2Error = "请输入登录密码!";
        return;
      }
      if (this.pwd1.length < 6) {
        this.step2Error = "密码至少6位!";
        return;
      }
      if (!this.pwd2) {
        this.step2Error = "请再次输入登录密码!";
        return;
      }
      if (this.pwd1 !== this.pwd2) {
        this.step2Error = "两次密码输入不相同,请检查!";
        return;
      }
      this.step2Error = "";
      this.step2Able = false;
      let channel = this.inviteCode ? "3" : "1";
      register(
        "1",
        this.mobile,
        this.pwd1,
        this.pwd2,
        "1",
        this.uuid,
        channel,
        null,
        this.inviteCode,
        null
      )
        .then(result => {
          this.step = 3;
          let token = result.data.token;
          let mobile = result.data.customerInfo.mobileTel;
          let userId = result.data.customerInfo.userId;
          setToken(token);
          setUserId(userId);
          setMobile(mobile);
          this.SET_TOKEN(token);
          this.SET_UID(userId);
          this.SET_MOBILE(mobile);
          localStorage.setItem(
            "customerInfo",
            JSON.stringify(result.data.customerInfo)
          );
        })
        .catch(error => {
          this.step2Error = error;
        })
        .finally(() => {
          this.step2Able = true;
        });
    },
    /** 前往开户 */
    goOpen() {
      this.$router.replace({
        name: "OpenAccount"
      });
    },
    /** 检查密码规则 */
    checkPwd1(e) {
      let value = e.target.value;
      value = value.replace(/^([a-zA-Z\d]*)?(.*)?/, "$1");
      this.pwd1 = value;
    },
    /** 检查密码规则 */
    checkPwd2(e) {
      let value = e.target.value;
      value = value.replace(/^([a-zA-Z\d]*)?(.*)?/, "$1");
      this.pwd2 = value;
    }
  }
};
</script>

<style lang="less" scoped>
.body {
  background: #f2f4f6 !important;
}
@orange: #ce9b63;
@gray: #c8c6c0;
.gray {
  color: @gray;
}
.orange {
  color: @orange;
}
.green {
  color: #00b900;
}
.red {
  color: #e6361e;
}
.btn-orange {
  font-size: 16px;
  color: #c58136;
  background: #f2cfaa;
  background-image: linear-gradient(120deg, #f6d7b5, #eac097);
  padding: 1em 0;
  text-align: center;
  border-radius: 4px;
}
input {
  font-size: 15px;
}
.btn-orange-plain {
  font-size: 16px;
  color: #c58136;
  border: 1px solid @orange !important;
  border-radius: 5px;
}
.disable-plain {
  color: @gray;
  border: 1px solid @gray !important;
  cursor: not-allowed;
}

.reg {
  background: #fff;
  font-size: 15px;
}
.nav {
  text-align: center;
}
.step {
  width: 600px;
  margin: auto;
  padding: 60px 0;
  .enter-mobile {
    padding: 60px;
    font-size: 26px;
    text-align: center;
  }
  .content-box {
    margin: auto;
    width: 80%;
  }
  .input-box {
    margin: 1em auto;
    border: 1px solid @gray;
    border-radius: 5px;
    padding: 1em;
    align-items: center;
    .ico {
      font-size: 24px;
    }
    & > input[type="text"],
    & > input[type="password"] {
      width: 100%;
    }
  }
  .mobile-box {
    padding: 0;
    & > div {
      padding: 0.8em;
      line-height: 24px;
    }
    .pre-mobile {
      border-right: 1px solid @gray;
      font-size: 14px;
    }
    .mobile {
      width: 270px;
      font: 18px "microsoft yahei";
    }
  }
  .msg-code-box {
    width: 60%;
  }
  .send-msg-box {
    width: 24%;
    margin-left: 1.5em;
  }
  .msg-error {
    color: #f00;
  }
  .inviter-box {
    padding: 1em 0;
    .ico {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .btn-next {
    width: 100%;
    font-size: 18px;
  }
  .agreement {
    line-height: 90px;
    text-align: center;
  }
}
.step-2 {
  .next {
    margin-top: 2em;
  }
}
.step-3 {
  text-align: center;
  .ico-success {
    font: bold 120px "microsoft yahei";
    color: #00b900;
    margin: 60px auto;
    display: block;
  }
  .success-msg {
    font-size: 1.2em;
    line-height: 2em;
    margin-bottom: 1.5em;
  }
  .go-open {
    padding: 1em;
    display: block;
    width: 300px;
    margin: auto;
  }
}
</style>
